<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>账号管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加账号</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button @click="addPower('new', '')" type="success" size="small">添加账号</el-button>
      <el-input v-model="searchData" placeholder="输入关键字搜索" clearable @clear="getadmin()" style="width:150px;margin:0 10px;" size="small" />
      <el-button @click="tableRes" type="primary" size="small">查询</el-button>
    </div>
    <el-table :data="list1" border style="width: 100%">
      <el-table-column label="编号" prop="id" width="60"></el-table-column>
      <el-table-column label="账号" prop="account" width="200"></el-table-column>
      <el-table-column label="密码" prop="pwd" width="200"></el-table-column>
      <el-table-column label="是否进线" prop="is_chat" width="120" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.is_chat == 2">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column label="身份" prop="level" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.level == 0">组员</span>
          <span v-if="scope.row.level == 1">班长</span>
          <span v-if="scope.row.level == 2">管理员</span>
        </template>
      </el-table-column>
      <el-table-column label="权限角色" prop="role_name" width="100"></el-table-column>
      <el-table-column fixed="right" width="220">
        <template slot-scope="scope">
          <div class="opt doc">
            <p>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="addPower('bj', scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="delPower(scope.row)">删除</el-button>
            </p>
            <p>
              <el-button size="mini" type="warning" icon="el-icon-setting" @click="setPower(scope.row)">设置权限</el-button>
              <el-button size="mini" v-if="mylevel == 2 && myid == scope.row.id" type="success" icon="el-icon-connection" @click="addRelation(scope.row)">添加班长</el-button>
            </p>
            <p>
              <el-button size="mini" v-if="mylevel == 2 && myid == scope.row.id" style="background-color:#585eaa;color:#fff;" icon="el-icon-setting" @click="setRelation(scope.row)">关系管理</el-button>
              <!-- <el-button size="mini" v-if="scope.row.level == 2" type="danger" icon="el-icon-link" @click="removeRelation(scope.row)">解除关系</el-button> -->
              <el-button size="mini" v-if="mylevel == 1 && myid == scope.row.id" type="success" icon="el-icon-view" @click="lookRelation(scope.row)">查看组员</el-button>
            </p>
            <p>
              <el-button size="mini" type="success" icon="el-icon-setting" @click="talkbinddoc(scope.row)">绑定科室</el-button>
            </p>

          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加账号 -->
    <el-dialog :title="title" :visible.sync="Create" width="60%">
      <el-form style="width: 80%;">
        <el-form-item label="账号:" label-width="180px">
          <el-input v-model="account"></el-input>
        </el-form-item>
        <el-form-item label="密码:" label-width="180px">
          <el-input v-model="pwd"></el-input>
        </el-form-item>
        <el-form-item label="发帖id:" label-width="180px">
          <el-input v-model="cust_id"></el-input>
        </el-form-item>
        <el-form-item label="服务id:" label-width="180px">
          <el-input v-model="servicer_id"></el-input>
        </el-form-item>
        <el-form-item label="请选择身份：" label-width="180px" style="text-align: left;">
          <el-radio v-model="level" label="2">管理员</el-radio>
          <el-radio v-model="level" label="1">班长</el-radio>
          <el-radio v-model="level" label="0">组员</el-radio>
        </el-form-item>
        <el-form-item label="是否进线：" label-width="180px" style="text-align: left;">
          <el-radio v-model="is_chat" label="2">是</el-radio>
          <el-radio v-model="is_chat" label="3">否</el-radio>
          <el-radio v-model="is_chat" label="7">监听</el-radio>
        </el-form-item>
        <el-form-item label="请选择权限角色：" label-width="180px" style="text-align: left;">
          <el-radio-group v-model="rolename">
            <el-radio @change="selectrole(item)" v-for="item in rolelist" :label="item.name" :key="item.id">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="头像:" label-width="180px">
          <upload :src.sync="headimg"></upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Create = false">取 消</el-button>
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 设置权限 -->
    <el-dialog title="设置权限" :visible.sync="Createpower" @close="closesetpower">
      <el-form style="width: 80%;">
        <el-form-item label="选择可操作的权限:" label-width="180px">
          <el-tree v-loading="loading" :data="powerdata" show-checkbox node-key="id" accordion ref="tree" highlight-current :props="defaultProps" @check="nodeclick">
          </el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="subpower" :disabled="discanclick">保 存</el-button>
        <el-button @click="closesetpower">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 设置权限备份 -->
    <!-- <el-dialog title="设置权限" :visible.sync="Createpower">
			<el-form style="width: 80%;">
				<el-form-item label="选择可操作的权限:" label-width="180px">
					<el-checkbox-group v-model="checklist">
						<el-checkbox @change="sourceFilter(item.id, $event)" :label="item.id" :key="item.id" v-for="item in menulist">{{ item.name }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="Createpower = false;checklist = [];">取 消</el-button>
			</div>
		</el-dialog> -->
    <!-- 查看组员 -->
    <el-dialog title="查看组员" :visible.sync="lookzy">
      <div>
        <span v-for="(item, index) in lookzylist" :key="index">{{ item.account }}、</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="
            lookzy = false;
            lookzylist = [];
          ">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 添加班长 -->
    <el-dialog title="添加班长" :visible.sync="addbz">
      <el-form style="width: 80%;">
        <el-form-item label="选择可操作的权限:" label-width="180px">
          <el-checkbox-group v-model="addbzlist">
            <el-checkbox @change="relationFilter(item.id, $event)" :label="item.id" :key="item.id" v-for="item in list">{{ item.account }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="
            addbz = false;
            addbzlist = [];
          ">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 绑定关系 -->
    <el-dialog title="关系管理" :visible.sync="relation" width="50%">
      <el-form>
        <div style="width:50%;float:left;">
          <el-form-item label="班长:" label-width="80px">
            <div class="relationdiv">
              <el-radio-group v-model="maIdList">
                <el-radio :label="item.id" class="selfline" :key="item.id" v-for="item in bzlist">{{
                  item.account
                }}</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </div>
        <div style="width:50%;float:left;">
          <el-form-item label="组员:" label-width="80px">
            <div class="relationdiv">
              <el-checkbox-group v-model="acIdList">
                <el-checkbox @change="zyrelationFilter(item.id, $event)" class="selfline" :label="item.id" :key="item.id" v-for="item in zylist">{{ item.account }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="
            relation = false;
            acIdList = [];
            maIdList = [];
          ">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 绑定医生 -->
    <el-dialog title="绑定科室" :visible.sync="binddoc" style="text-align:left;">
      <el-button class="button-new-tag" size="small" @click="binddocadd(1)" style="margin-bottom:20px;">+ 添加</el-button>
      <div>
        已绑定科室:
        <el-tag v-for="tag in tags1" :key="tag.id" closable :disable-transitions="true" @close="handleClose(tag)" style="margin-right: 8px;">
          {{ tag.docName }}
        </el-tag>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="
            binddoc = false;
            tags1 = [];
          ">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 选择科室 -->
    <el-dialog title="选择科室" :visible.sync="pushDoc" width="60%">
      <div style="width: 100%; height: auto; float: left">
        <el-input placeholder="请输入科室" clearable prefix-icon="el-icon-search" v-model="pusgDocSearch" style="width: 26%; float: left" @clear="cleardoc">
        </el-input>
        <el-button type="primary" style="float: left; margin-left: 20px" @click="sendDocQuery">查询</el-button>
      </div>
      <div style="width: 100%;height: 40px;line-height: 40px;float: left;text-align: left;">
        您已选择的科室：{{ selectDocList }}
      </div>
      <el-table :data="doclist" border style="width: 100%; margin-top: 40px" tooltip-effect="dark" @selection-change="handleSelectionChange" :row-key="
          (row) => {
            return row.id;
          }
        " ref="multipleTable">
        <el-table-column type="selection" :reserve-selection="true" width="55"></el-table-column>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="name" label="科室"></el-table-column>
        <!-- <el-table-column prop="age" label="年龄"></el-table-column>
        <el-table-column prop="sex" label="性别"></el-table-column>
        <el-table-column prop="hospital" label="所在医院"></el-table-column>
        <el-table-column prop="rank" label="职称"></el-table-column>
        <el-table-column prop="goodat" label="擅长治疗"></el-table-column> -->
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="
            pushDoc = false;
            toggleSelection();
            pusgDocSearch = '';
            doclist = [];
            selectDocList = '';
          ">取 消</el-button>
        <el-button type="primary" @click="subDoc">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 选择科室 -->
  </div>
</template>
<script>
import PowerManage from "../assets/PowerManage.js";
export default PowerManage;
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}
.opt p{
  margin: 0 0 8px 0;
}
.el-checkbox {
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 15px;
  float: left;
}

.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.relationdiv {
  border: 1px solid #ebeef5;
  text-align: left;
  padding-left: 10px;
  height: 300px;
  overflow-y: auto;
  border-radius: 4px;
}
.selfline {
  float: left;
  width: 100%;
  line-height: 40px;
}
</style>
