import upload from "../components/upload";
export default {
  components: {
    upload,
  },
  inject: ["reload"],
  data() {
    return {
      title: "添加账号",
      Create: false,
      Createpower: false,
      account: "",
      pwd: "",
      cust_id: "",
      servicer_id: "",
      headimg: "",
      list: [],
      list1: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
      menulist: [],
      checklist: [],
      account: "",
      pwd: "",
      type: "",
      item: "",
      poweritem: "",
      is_chat: "2",
      yuanischat: "",
      sid: "",
      level: "0",
      acIdList: [],
      relation: false,
      relationitem: "",
      zylist: [],
      bzlist: [],
      maIdList: "",
      id: sessionStorage.getItem("id"),
      //查看组员
      lookzy: false,
      lookzylist: [],
      //添加班长
      addbz: false,
      addbzlist: [],
      mylevel: sessionStorage.getItem("level"),
      myid: sessionStorage.getItem("id"),
      defaultProps: {
        children: "children",
        label: "name",
      },
      powerdata: [],
      powerchecklist: [],
      loading: false,
      rolename: "",
      rolelist: [],
      binddoc: false, //绑定医生
      tags1: [],
      pushDoc: false,
      pusgDocSearch: "",
      selectDocList: "",
      doclist: [],
      multipleSelection: [],
      binddocitem: "",
      searchData: "",
      discanclick: true,
    };
  },
  watch: {
    maIdList(o, n) {
      this.axios.get("/ma/get_gu_ma_admin").then((res) => {
        if (res.data.code == 0) {
          this.zylist = res.data.result;
        }
      });
      this.getzylist(o);
    },
  },
  mounted() {
    this.getadmin();
    this.getqx();
  },
  methods: {
    selectrole(val) {
      console.log(val);
      this.role_id = val.id;
      this.rolename = val.name;
    },
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function(v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
    },
    getadmin() {
      this.axios
        .get("/ma/get_gu_ma_admin")
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.list1 = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getqx() {
      this.axios
        .get("/ma/get_gu_ma_user_right")
        .then((res) => {
          if (res.data.code == 0) {
            this.menulist = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getrolelist() {
      this.axios
        .get("/ma/get_gu_ma_role")
        .then((res) => {
          if (res.data.code == 0) {
            this.rolelist = res.data.result;
            console.log(res.data.result);
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      // this.getdata(currentPage);
    },
    addPower(type, item) {
      this.Create = true;
      this.type = type;
      this.item = item;
      this.yuanischat = item.is_chat + "";
      this.sid = item.id;
      this.getrolelist();
      if (type == "new") {
        this.title = "添加账号";
        this.account = "";
        this.pwd = "";
        this.cust_id = "";
        this.servicer_id = "";
        this.headimg = "";
        this.is_chat = "2";
        this.level = "0";
        this.rolename = "";
        this.role_id = "";
      } else {
        this.account = item.account;
        this.pwd = item.pwd;
        this.cust_id = item.cust_id;
        this.servicer_id = item.servicer_id;
        this.headimg = item.headimg;
        this.is_chat = item.is_chat + "";
        this.title = "编辑账号";
        this.level = item.level + "";
        this.rolename = item.rolename;
        this.role_id = item.role_id;
      }
    },
    sub() {
      let url = "";
      let str = "";
      var form = new Object();
      if (this.servicer_id == "" || this.servicer_id == null || this.servicer_id == undefined) {
        this.$message({
          message: "请设置服务ID",
          type: "error",
        });
        return;
      } else {
        form = {
          account: this.account,
          pwd: this.pwd,
          cust_id: this.cust_id,
          servicer_id: this.servicer_id,
          headimg: this.headimg,
          is_chat: this.is_chat,
          uid: sessionStorage.getItem("id"),
          level: this.level,
          role_name: this.rolename,
          role_id: this.role_id,
        };
      }
      if (this.type == "new") {
        url = "/ma/insert_gu_ma_admin";
        str = "添加成功";
      } else {
        url = "/ma/update_gu_ma_admin";
        str = "修改成功";
        form.id = this.item.id;
      }
      console.log(form);
      this.axios
        .post(url, this.qs.stringify(form))
        .then((rs) => {
          if (rs.data.code == 0) {
            if (this.yuanischat != this.is_chat) {
              // console.log('改变了')
              this.axios
                .post(
                  this.serveurl.serve + "/api/User/kick",
                  this.qs.stringify({ serviceId: "s" + this.sid })
                )
                .then((res) => {
                  console.log(res);
                });
            }
            this.dialogFormVisible = false;
            this.form = {};
            this.$message({
              message: str,
              type: "success",
            });
            this.reload();
          } else {
            this.$message(rs.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delPower(row) {
      this.$confirm("此操作将永久删除账号, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/ma/delete_gu_ma_admin", this.qs.stringify({ id: row.id }))
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.reload();
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    closesetpower() {
      this.Createpower = false;
      this.discanclick = true;
      this.$refs.tree.setCheckedKeys([]);
    },
    //设置权限按钮
    setPower(row) {
      this.powerdata = [];
      this.axios.get("/ma/get_gu_ma_user_right").then((res) => {
        if (res.data.code == 0) {
          var arr = res.data.result;
          arr.forEach((item) => {
            if ("children" in item) {
              this.powerdata.push(item);
            }
          });
          var list = [];
          this.loading = true;
          this.axios
            .get("/ma/get_gu_ma_user_right_by_admin?admin_id=" + row.id)
            .then((res) => {
              if (res.data.code == 0) {
                if (res.data.result.length > 0) {
                  let arr = res.data.result;
                  for (let i = 0; i < arr.length; i++) {
                    const element = arr[i];
                    list.push(element.id);
                    if ("children" in element) {
                      var list2 = element.children;
                      for (let m = 0; m < list2.length; m++) {
                        const ele = list2[m];
                        list.push(ele.id);
                      }
                    }
                  }
                  list.forEach((value) => {
                    this.$refs.tree.setChecked(value, true, false);
                  });
                  // console.log(list);
                  this.powerchecklist = list;
                  this.loading = false;
                } else {
                  this.loading = false;
                }
              } else {
                this.loading = false;
              }
            })
            .catch((err) => {
              console.log(err);
            });

          this.Createpower = true;
          this.poweritem = row;
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
    //设置权限（一级权限旧版）
    sourceFilter(id, val) {
      let form = {
        admin_id: this.poweritem.id,
        right_id: id,
      };
      let url = "";
      console.log(form);
      if (val == true) {
        url = "/ma/insert_gu_ma_user_right";
      } else {
        url = "/ma/delete_gu_ma_user_right";
      }
      this.axios
        .post(url, this.qs.stringify(form))
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res);
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加班长按钮
    addRelation(row) {
      this.addbzlist = [];
      this.axios
        .get("/ma/get_gu_ma_cs_relationship_by_maid?ma_id=" + row.id)
        .then((res) => {
          if (res.data.code == 0) {
            let arr = res.data.result;
            for (let i = 0; i < arr.length; i++) {
              const element = arr[i];
              this.addbzlist.push(element.id);
            }
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.addbz = true;
      this.relationitem = row;
    },
    //获取组员列表
    getzylist(o) {
      console.log(o);
      this.acIdList = [];
      this.axios
        .get("/ma/get_gu_ma_cs_relationship_by_maid?ma_id=" + o)
        .then((res) => {
          if (res.data.code == 0) {
            let arr = res.data.result;
            for (let i = 0; i < arr.length; i++) {
              const element = arr[i];
              this.acIdList.push(element.id);
            }
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取班长列表
    getbzlist() {
      this.axios
        .get("/ma/get_gu_ma_cs_relationship_by_maid?level=1&ma_id=" + this.id)
        .then((res) => {
          if (res.data.code == 0) {
            this.bzlist = res.data.result;
          }
        });
    },
    //添加组员选择时
    zyrelationFilter(id, val) {
      let form = {};
      let url = "";
      if (val == true) {
        url = "/ma/insert_gu_ma_cs_relationship";
        form = {
          ac_ids: id,
          ma_id: this.maIdList,
        };
      } else {
        form = {
          ac_id: id,
          ma_id: this.maIdList,
        };
        url = "/ma/delete_gu_ma_cs_relationship";
      }
      this.axios
        .post(url, this.qs.stringify(form))
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res);
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加班长选择时
    relationFilter(id, val) {
      let form = {};
      let url = "";
      if (val == true) {
        url = "/ma/insert_gu_ma_cs_relationship";
        form = {
          ac_ids: id,
          ma_id: this.relationitem.id,
        };
      } else {
        form = {
          ac_id: id,
          ma_id: this.relationitem.id,
        };
        url = "/ma/delete_gu_ma_cs_relationship";
      }
      this.axios
        .post(url, this.qs.stringify(form))
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res);
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //关系管理
    setRelation() {
      this.relation = true;
      // this.getzylist();
      this.getbzlist();
    },
    //查看组员
    lookRelation(row) {
      this.lookzy = true;
      this.axios.get("/ma/get_gu_ma_cs_relationship_by_maid?ma_id=" + row.id).then((res) => {
        if (res.data.code == 0) {
          this.lookzylist = res.data.result;
        }
      });
    },
    //权限选择
    nodeclick(a, b) {
      let parentArr = this.$refs.tree.getHalfCheckedKeys();
      let childArr = this.$refs.tree.getCheckedKeys();
      this.powerchecklist = parentArr.concat(childArr);
      this.discanclick = false;
    },
    //提交权限
    subpower() {
      let url = "";
      url = "/ma/insert_gu_ma_user_right";
      let str = "";
      for (let a = 0; a < this.powerchecklist.length; a++) {
        str += this.powerchecklist[a] + ",";
      }
      let form = {
        admin_id: this.poweritem.id,
        right_ids: str,
        uid: sessionStorage.getItem("id"),
      };
      
      this.$confirm("是否确认修改权限?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // console.log(form);
          this.axios
            .post(url, this.qs.stringify(form))
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "保存成功",
                });
                this.discanclick = true;
                this.setPower({id:this.poweritem.id});
                this.axios
                  .post(
                    "/log/chat_login_log",
                    this.qs.stringify({
                      data:
                        "【修改权限】 " +
                        sessionStorage.getItem("id") +
                        "  ---   " +
                        sessionStorage.getItem("name"),
                    })
                  )
                  .then((res) => {});
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
          // this.Createpower = false;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消操作",
          });
        });
    },

    //绑定医生
    talkbinddoc(row) {
      this.binddoc = true;
      this.binddocitem = row;
      this.getbinddoc(row.id);
    },
    //添加医生
    binddocadd() {
      this.pushDoc = true;
      this.multipleSelection = [];
      //   this.toggleSelection();
      this.pusgDocSearch = "";
      this.doclist = [];
      this.selectDocList = "";
    },
    getbinddoc(id) {
      this.axios
        .get(this.serveurl.serve + "/api/ServiceWithDoctor/get_bind_list?service=s" + id)
        .then((res) => {
          if (res.data.code == 1) {
            this.tags1 = res.data.result;
          }
        });
    },
    handleClose(row) {
      this.$confirm("此操作将解绑该科室, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              this.serveurl.serve + "/api/ServiceWithDoctor/unbind",
              this.qs.stringify({ id: row.id })
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  type: "success",
                  message: "解绑成功",
                });
                this.tags1.splice(this.tags1.indexOf(row), 1);
              } else {
                this.$message(rs.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    cleardoc() {
      //清除医生
      this.doclist = [];
    },
    sendDocQuery() {
      //搜索医生
      this.axios
        .get("/gu/get_gu_department_like_name?dp_name=" + this.pusgDocSearch)
        .then((rs) => {
          if (rs.data.code == 0) {
            this.doclist = rs.data.result;
            this.totalCount = rs.data.result.length;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到科室",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSelectionChange(val) {
      //多选中数组操作
      this.multipleSelection = val;
      let str = "";
      if (val.length > 0) {
        for (let a = 0; a < val.length; a++) {
          str += val[a].name + "、";
        }
        this.selectDocList = str;
      } else {
        this.selectDocList = "";
      }
    },
    toggleSelection() {
      //取消选择医生
      this.$refs.multipleTable.clearSelection();
      this.multipleSelection = [];
    },
    subDoc() {
      console.log(this.multipleSelection);
      var show = true;
      var arr = this.multipleSelection;
      var j = arr.length;
      for (let m = 0; m < arr.length; m++) {
        const element = arr[m];
        this.axios
          .post(
            this.serveurl.serve + "/api/ServiceWithDoctor/bind",
            this.qs.stringify({
              service: "s" + this.binddocitem.id,
              docId: element.id,
              docName: element.name,
            })
          )
          .then((res) => {
            if (res.data.code == 1) {
              console.log(res);
            }
          });
        if (j == m) {
          show = false;
          break;
        }
      }
      if (show) {
        this.$message({
          type: "success",
          message: "绑定成功",
        });
        this.pushDoc = false;
        this.toggleSelection();
        this.getbinddoc(this.binddocitem.id);
      }
    },
    //搜索
    tableRes() {
      const search = this.searchData.trim();
      const table = this.list;
      const array = table.filter((item) => {
        if (item.account.includes(search)) {
          return item;
        }
      });
      // console.log(array);
      this.list1 = [];
      this.list1 = array;
    },
  },
};
